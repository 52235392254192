import React from 'react';
import styled from 'styled-components';

import TechnologyLayout from "./TechnologyLayout"
import LogoIcon from "../assets/svg/logo.svg";

const Description = styled.div`
  display: flex;
  
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const ImageContainer = styled.div`
  width: 600px;
  padding-top: 50px;
  padding-left: 50px;
  display: flex;
  flex-direction: column;
  
  @media screen and (max-width: 768px) {
    width: 320px;
    padding-top: 10px;
    padding-left: 0;
    margin: 0 auto;
  }
`;

const ImageCaption = styled.div`
  border-bottom: 3px solid black;
  padding-bottom: 10px;
  margin-bottom: 20px;
`;

const NoImage = styled.div`
  background-color: lightgray;
  width: 550px;
  height: 380px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  @media screen and (max-width: 768px) {
    width: 320px;
    height: 200px;
  }
`;

const TextContainer = styled.div`
  font-size: 16px;
  padding-left: 40px;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  @media screen and (max-width: 768px) {
    padding-left: 0;
    padding-top: 40px;
    font-size: 14px;
  }
`;

const TextRow = styled.div`
  display: flex;
  margin-bottom: 20px;
`

const TextKey = styled.div`
  width: 150px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  
  ::before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    clip-path: polygon(30% 0, 70% 0, 100% 50%, 70% 100%, 30% 100%, 60% 50%);
    background-color: #203864;
  }
  @media screen and (max-width: 768px) {
    width: 100px;
  }
`;

const Logo = styled(LogoIcon)`
  width: 150px;
  height: auto;
  text-decoration: none;
  opacity: 0.6;
  & path {
    fill: black;
  }
`

function PerformanceDetailPage ({pageContext}) {

  return (
    <TechnologyLayout title="시공 실적">
      <Description>
        <ImageContainer>
          <ImageCaption>
            {pageContext.name}
          </ImageCaption>
          <NoImage><Logo /></NoImage>
        </ImageContainer>
        <TextContainer>
          <TextRow><TextKey>발주처 </TextKey> {pageContext.client}</TextRow>
          <TextRow><TextKey>기간 </TextKey> {pageContext.start_date} ~ {pageContext.end_date}</TextRow>
        </TextContainer>
      </Description>
    </TechnologyLayout>
  )
}

export default PerformanceDetailPage