import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled, { createGlobalStyle } from "styled-components"

import SEO from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"
import TopImageSrc from "../images/top_image_03.jpg"


const Layout = styled.main`
  width: 1000px;
  margin: auto;
  padding-top: 80px;
  padding-bottom: 200px;
  box-sizing: border-box;
  min-height: calc(100vh - 67px);
  font-family: "NanumSquare", georgia, serif;
  user-select: none;
  
  @media screen and (max-width: 768px) {
    width: 100vw;
    min-width: 360px;
    padding: 50px 20px 80px;
  }
`;

const GlobalStyle = createGlobalStyle`
  :root {
    --logo-color: #323593;
    --first-color: #555b6e;
    --second-color: #89b0ae;
    --third-color: #bee3db;
    --fourth-color: #ffd6ba;
    --fifth-color: #faf9f9;
  }
  
  html {
    overflow-x: hidden;
    scroll-behavior: smooth;
  }
  
  body {
    margin: 0;
    padding: 0;
    border: 0;
  }
`

const ImageContainer = styled.div`
  height: 300px;
  overflow: hidden;
  object-fit: cover;
  position: relative;
  background-color: black;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
  @media screen and (max-width: 768px) {
    height: 250px;
  }
`;

const TopImage = styled.img`
  position: absolute;
  min-width: 1500px;
  width: 100%;
  top: -350px;
  left: 0;
  opacity: 0.6;
`
const TextContainer = styled.div`
  position: relative;
  z-index: 2;
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  text-decoration: white;
  width: 270px;
  line-height: 100px;
  height: 100px;
  border: 5px solid white;
  
  @media screen and (max-width: 768px) {
    margin-top: 30px;
    font-size: 30px;
    width: 180px;
    line-height: 80px;
    height: 80px;
  }
`;

const SubTitle = styled.div`
  position: relative;
  z-index: 2;
  margin-top: 15px;
  font-size: 30px;
  
  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
`;

const TechnologyLayout = ({title, children}) => {
  const data = useStaticQuery(graphql`
      query {
          site {
              siteMetadata {
                  title
              }
          }
      }
  `)

  return (
    <>
      <GlobalStyle />
      <SEO title={title} />
      <Header siteTitle={data.site.siteMetadata.title} />
      <ImageContainer>
        <TopImage src={TopImageSrc} alt="기술 개발 이미지"/>
        <TextContainer>기술 개발</TextContainer>
        <SubTitle>{title}</SubTitle>
      </ImageContainer>
      <Layout>
        {children}
      </Layout>
      <Footer />
    </>
  )
}

TechnologyLayout.propTypes = {
  children: PropTypes.node,
}

export default TechnologyLayout
