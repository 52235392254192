import { Link } from "gatsby"
import React, { useState } from "react"
import styled, { css } from "styled-components"
import { StaticQuery, graphql } from "gatsby"
import LogoImage from "../assets/svg/logo.svg"
import MenuCollapse from "../assets/svg/main_menu.svg"
import Close from "../assets/svg/close.svg"

const LogoContainer = styled(Link)`
  position: absolute;
  margin-bottom: 0;
  width: 80px;
  display: inline-block;
  transition: opacity .3s;
  left: 40px;
  top: -10px;
  
  @media screen and (max-width: 768px) {
    opacity: 1;
    position: relative;
    top: 0;
    left: unset;
    width: 80px;
  }
`;

const HeaderContainer = styled.header`
  background-color: #3D405B;
  position: relative;
  width: 100vw;
  min-width: 1400px;
  height: 50px;
  top: 0;
  z-index: 11;
  transition: height .3s, background-color .2s, padding .3s;
  
  :hover {
    background-color: #3D405B;
  }
  
  @media screen and (max-width: 768px) {
    position: fixed;
    min-width: 360px;
    height: 50px;
    background-color: #3D405B;
  }
`;

const Logo = styled(LogoImage)`
  width: 100%;
  text-decoration: none;
  & path {
    fill: white;
  }
`;

const MegaMenuPanel = styled.div`
  display: block;
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  margin: 0;
  height: 0;
  background: var(--fifth-color);
  transition: height 0.2s;
  overflow: hidden;
  
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const MenuContainer = styled.div`
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  float: right;
  padding: 10px 320px 10px 0;
  width: 920px;
  
  :hover ${MegaMenuPanel} {
    transform: translateY(0);
    height: 300px;
  }
  
  @media screen and (max-width: 768px) {
    width: 100%;
    min-width: 360px;
    padding: 0;
    height: 50px;
    justify-content: center;
  }
`;

const Menu = styled.div`
  color: white;
  font-size: 18px;
  transition: font-size .3s;
  
  @media screen and (max-width: 768px) {
    display: none;
  }
`

const MegaMenuColumnContainer = styled.nav`
  width: 600px;
  margin-right: 320px;
  margin-left: auto;
  display: flex;
  justify-content: space-around;
  text-align: center;
  
`;


const MegaMenuColumn = styled.div`
  width: 150px;
`;

const MegaMenu = styled(Link)`
  margin-top: 20px;
  color: black;
  text-decoration: none;
  display: block;
`;

const MegaMenuCollapse = styled(MenuCollapse)`
  width: 40px;
  height: 40px;
  display: none;
  position: absolute;
  right: 5px;
  
  @media screen and (max-width: 768px) {
    display: ${props => props.show ? 'block' : 'none'}
  }
`

const CloseButton = styled(Close)`
  width: 40px;
  height: 40px;
  top: 5px;
  position: absolute;
  right: 5px;
  
  g {
    fill: white;
  }
`

const MobileMegaMenuPanel = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 250px;
  height: 100vh;
  overflow: hidden;
  transform: ${props => props.open ? css`translateX(0)` : css`translateX(250px)`};
  transition: transform .3s ease-in-out;
  background-color: rgba(1, 1, 1, 0.9);
  padding-top: 20px;
`

const MobileMegaMenuContainer = styled.div`
  padding-left: 20px;
  color: white;
  font-size: 25px;
  margin-top: 30px;
`

const MobileMegaMenu = styled(Link)`
  color: var(--second-color);
  display: block;
  text-decoration: none;
  padding-left: 20px;
  margin-top: 20px;
  font-size: 20px;
  cursor: pointer;
`

const Header = () => {
  const [isMobilePanelOpen, setIsMobilePanelOpen] = useState(false)

  const openMobilePanel = () => {
    setIsMobilePanelOpen(true)
  }
  const closeMobilePanel = () => {
    setIsMobilePanelOpen(false)
  }

  return (
    <StaticQuery
      query={
        graphql`
          query {
              allMenuItemsJson {
                  edges {
                      node {
                          name
                          link
                          subMenu {
                            name
                            link
                          }
                      }
                  }
              }
          }
        `
      }
      render={data => (
        <HeaderContainer >
          <MenuContainer>
            <LogoContainer to="/">
              <Logo />
            </LogoContainer>
            <MegaMenuCollapse
              show={!isMobilePanelOpen}
              onClick={openMobilePanel} />
            {data.allMenuItemsJson.edges.map(value => (
              <Menu key={value.node.name}>{value.node.name}</Menu>
            ))}
            <MegaMenuPanel>
              <MegaMenuColumnContainer>
                {data.allMenuItemsJson.edges.map(({node}) => (
                  <MegaMenuColumn key={node.name}>
                    {node.subMenu.map(value => (
                      <MegaMenu
                        key={value.name}
                        to={node.link + value.link}>
                        {value.name}
                      </MegaMenu>
                    ))}
                  </MegaMenuColumn>
                ))}
              </MegaMenuColumnContainer>
            </MegaMenuPanel>
            <MobileMegaMenuPanel open={isMobilePanelOpen}>
              <CloseButton
                onClick={closeMobilePanel} />
              {data.allMenuItemsJson.edges.map(({node}) => (
                <MobileMegaMenuContainer key={node.name}>
                  {node.name}
                  {node.subMenu.map(value => (
                    <MobileMegaMenu
                      key={value.name}
                      to={node.link + value.link}>
                      {value.name}
                    </MobileMegaMenu>
                  ))}
                </MobileMegaMenuContainer>
              ))}
            </MobileMegaMenuPanel>
          </MenuContainer>

        </HeaderContainer>
      )}
    />
  )
}

export default Header
